<div>
  <span style="font-weight: 500; margin-right: 6px; font-size: 24px;">Tarifs</span>
  <span *ngIf="isTarifsDuParent">
    <i id="tarifLegendeIcon" style="font-size: 18px; cursor: pointer;" class="dx-icon-info" (mouseenter)="showPopover = true" (mouseleave)="showPopover = false"></i>
  </span>
  <dx-button *ngIf="!isTarifsDuParent" icon="edit" stylingMode="text" (onClick)="showModeAvance()"></dx-button>
</div>

<dx-list [dataSource]="tarifs">
  <div *dxTemplate="let item of 'item'">
    <div style="font-weight: 500; font-size: 1.1rem;">{{ item.prixIntitule }}</div>
    <div style="padding-left: 12px;">
      <span style="font-weight: 500; margin-right: 6px;">{{ formatNumber(item.valeur, { type: 'fixedPoint', precision: 0 }) + ' F' }}</span>
      <span *ngIf="item.prixTypeTaxe === TypeTaxe.ht" class="textSecondary">{{ formatNumber(item.prixTtc, { type: 'fixedPoint', precision: 0 }) + ' F' }} TTC</span>
      <span *ngIf="item.prixTypeTaxe === TypeTaxe.ttc" class="textSecondary">{{ formatNumber(item.prixHt, { type: 'fixedPoint', precision: 0 }) + ' F' }} HT</span>
    </div>
  </div>
</dx-list>

<dx-popup
  [fullScreen]="false"
  [showCloseButton]="true"
  [(visible)]="isModeAvance"
  (onHiding)="hideModeAvance()"
  title="Tarifs"
>
  <app-tarif-article-list [tarifsDto]="tarifs"></app-tarif-article-list>
</dx-popup>

<dx-popover target="#tarifLegendeIcon" [width]="300" [(visible)]="showPopover">
  <div *dxTemplate="let data = model; of: 'content'">
    <span *ngIf="tarifs?.length == 1" style="color: grey;">Le tarif ci-dessous provient de l'article modèle</span>
    <span *ngIf="tarifs?.length > 1" style="color: grey;">Les tarifs ci-dessous proviennent de l'article modèle</span>
  </div>
</dx-popover>