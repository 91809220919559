<dx-popup
  [title]="titleText"
  [(visible)]="visible"
  [fullScreen]="!!(screen.xSmallScreenChanged | async)"
  [width]="width"
  (onHidden)="close()"
  [height]="height"
>
  <dxi-toolbar-item toolbar="bottom" location="center">
    <div
      class="form-popup-buttons-container"
      [class.flex-buttons]="width <= 360"
    >
      <dx-button
        text="Annuler"
        stylingMode="outlined"
        type="normal"
        (onClick)="close()"
      ></dx-button>
      <dx-button
        [text]="saveButtonText"
        stylingMode="contained"
        type="default"
        [disabled]="isSaveDisabled"
        (onClick)="onSaveClick()"
      ></dx-button>
      <dx-button
      *ngIf="showButonSaveAndOpen"
        text="Créer et ouvrir"
        stylingMode="contained"
        type="success"
        [disabled]="isSaveDisabled"
        (onClick)="onSaveAnOpenClick()"
      ></dx-button>
    </div>
  </dxi-toolbar-item>
  <dx-validation-group #validationGroup>
    <ng-content></ng-content>
  </dx-validation-group>
</dx-popup>