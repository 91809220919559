import {ChangeDetectorRef, Component, Input, ViewChild} from "@angular/core";
import {ArticlesLexiClient, CaracteristiquesLexiClient, CaracteristiqueDto, VarianteArticleDto, ObjectType, ArticleForUpdateDto} from "@lexi-clients/lexi";
import { DxDataGridComponent, DxFormComponent } from "devextreme-angular";
import {lastValueFrom} from "rxjs";

@Component({
    selector: "app-variantes-article-list",
    templateUrl: "./variantes-article-list.component.html",
    styleUrls: ["./variantes-article-list.component.scss"],
})
export class VariantesArticleListComponent {
    @ViewChild("form") form: DxFormComponent;
    private _dataGrid: DxDataGridComponent;
    get dataGrid() { return this._dataGrid }
    @ViewChild(DxDataGridComponent, { static: false }) set dataGrid(value: DxDataGridComponent) {
        this._dataGrid = value;
        this.setDatagridHeight();
        this.cdr.detectChanges();
    }

    _offsetTopInPx: number;
    get offsetTopInPx(): number { return this._offsetTopInPx; }
    @Input() set offsetTopInPx(value: number) {
        this._offsetTopInPx = value;
        this.setDatagridHeight();
    }

    _article: ArticleForUpdateDto;
    get article(): ArticleForUpdateDto { return this._article; }
    @Input() set article(value: ArticleForUpdateDto) {
        this._article = value;
        if (value != null) {
            this.setVariantes();
            this.setCaracteristiques();
        }
    }

    caracteristiques: CaracteristiqueDto[] = [];
    variantes: VarianteArticleDto[] = [];

    constructor(
        private readonly articlesLexiClient: ArticlesLexiClient,
        private readonly caracteristiquesLexiClient: CaracteristiquesLexiClient,
        private readonly cdr: ChangeDetectorRef,
    ) { }

    async setCaracteristiques() {
        this.caracteristiques = await lastValueFrom(this.caracteristiquesLexiClient.getCaracteristiquesByObjectType(ObjectType.article));
    }

    async setVariantes() {
        this.variantes = await lastValueFrom(this.articlesLexiClient.getVariantes(this.article.id));
    }

    async onRowInserting(variante: VarianteArticleDto) {
        try {
            variante.articleParentId = this.article.id;
            await lastValueFrom(this.articlesLexiClient.creerVariante(this.article.id, variante));
        } finally {
            await this.setVariantes();
        }
    }

    async onRowUpdating(e: { oldData: VarianteArticleDto; newData: VarianteArticleDto }) {
        try {
            for (const field of Object.keys(e.newData)) {
                e.oldData[field] = e.newData[field];
            }
            await lastValueFrom(this.articlesLexiClient.modifierVariante(e.oldData));
        } finally {
            await this.setVariantes();
        }
    }

    async onRowRemoving(variante: VarianteArticleDto) {
        try {
            await lastValueFrom(this.articlesLexiClient.supprimer(variante.id));
        } finally {
            await this.setVariantes();
        }
    }

    /*
      e.data = les changements
      e.key = les données déjà présente
     */
      onSaving(e: {
        cancel: boolean;
        changes: Array<{ type: string; data: VarianteArticleDto; key: VarianteArticleDto }>;
    }) {
        if (this.form?.instance?.validate != null) {
            e.cancel = this.form.instance.validate().isValid;
        }
    }

    onInitNewRow(e: VarianteArticleDto) {
        e.libelleLong = this.article.libelleLong;
        e.variation1CaracteristiqueId = this.article.variation1CaracteristiqueId;
        e.variation2CaracteristiqueId = this.article.variation2CaracteristiqueId;
    }

    setDatagridHeight() {
      if (this.dataGrid == null || this.offsetTopInPx == null) return;
      this.dataGrid.instance.element().style.height = `calc(100vh - ${this.offsetTopInPx}px)`;
    }
}
