<dx-data-grid
  #dataGrid
  height="100%"
  [dataSource]="magasinPieceNatureStore"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  (onKeyDown)="onKeyDown($event)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item position="after">
      <div *dxTemplate>
        <app-reset-grid-state [dataGrid]="dataGrid"></app-reset-grid-state>
      </div>
    </dxi-item>
    <dxi-item position="after">
      <div *dxTemplate>
        <dx-button icon="refresh" stylingMode="text" hint="Rafraîchir" (onClick)="setDataSource()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="addRowButton" position="after"></dxi-item>
    <dxi-item name="columnChooserButton" position="after"></dxi-item>
    <dxi-item name="searchPanel" position="after"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-filter-row [visible]="false"></dxo-filter-row>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
  <dxo-state-storing [enabled]="true" [storageKey]="dataGridStorageKey" appStorage [withFilters]="false"></dxo-state-storing>
  <dxo-paging [enabled]="true" [pageSize]="20"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[20, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-scrolling mode="virtual"></dxo-scrolling>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
  <dxo-remote-operations [sorting]="true" [grouping]="true" [filtering]="true" [paging]="true"></dxo-remote-operations>
  <dxo-editing mode="row" [allowAdding]="!siteReadOnly" [allowUpdating]="!siteReadOnly"></dxo-editing>

  <!-- Colonnes -->
  <dxi-column [allowGrouping]="true" dataField="siteId" caption="Site" [visible]="societeId != null" sortOrder="asc">
    <dxo-lookup [dataSource]="siteDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
    <dxi-validation-rule type="required" *ngIf="societeId != null"></dxi-validation-rule>
  </dxi-column>
  <dxi-column [allowGrouping]="false" dataField="pieceNatureId" caption="Nature pièce">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="pieceNatureDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column [allowGrouping]="false" dataField="libelle" caption="Intitulé">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxi-validation-rule type="stringLength" [max]="100"></dxi-validation-rule>
  </dxi-column>
  <dxi-column [allowGrouping]="false" dataField="defaultMouvementTypeId" caption="Type de mouvement">
    <dxo-lookup [dataSource]="mouvementTypeDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column [allowGrouping]="false" dataField="validitePiece" caption="Validité (en jours)">
    <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
  </dxi-column>
  <dxi-column [allowGrouping]="false" [allowSearch]="false" dataField="actif" caption="Actif" dataType="boolean" [width]="100"></dxi-column>
</dx-data-grid>