import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ObjectType, TrackingField, TypeUtilisateur, UserDto, UtilisateursLexiClient } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';
import { objectNavigations, TrackingItemService } from 'lexi-angular/src/app/services/tracking-items.service';
import { lastValueFrom } from 'rxjs';
import { ReferenceService } from '../references/references.service';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { formatDate } from '@angular/common';
import { ExportingEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-audit-piste',
  templateUrl: './audit-piste.component.html',
  styleUrls: ['./audit-piste.component.scss'],
})
export class AuditPisteComponent implements OnInit {
  private _dataGrid: DxDataGridComponent;
  get dataGrid() { return this._dataGrid }
  @ViewChild(DxDataGridComponent, { static: false }) set dataGrid(value: DxDataGridComponent) {
    this._dataGrid = value;
    this.setDatagridHeight();
    this.cd.detectChanges();
  }

  _offsetTopInPx: number;
  get offsetTopInPx(): number { return this._offsetTopInPx; }
  @Input() set offsetTopInPx(value: number) {
    this._offsetTopInPx = value;
    this.setDatagridHeight();
  }

  @Input() objectType?: ObjectType;
  @Input() objectId?: number;

  objectTypes = [];
  utilisateurs: UserDto[] = [];
  dataSource: CustomStore;
  filterValue = null;
  objectNavigations = objectNavigations;

  constructor(
    private readonly referenceService: ReferenceService,
    private readonly trackingItemService: TrackingItemService,
    private readonly utilisateurService: UtilisateursLexiClient,
    private readonly cd: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.objectTypes = this.referenceService.getObjectTypes();
    this.initFilters();
    this.dataSource = new CustomStoreService(this.trackingItemService).getCustomStore();
    this.utilisateurs = await lastValueFrom(this.utilisateurService.getUtilisateurs(TypeUtilisateur.collaborateur, true, false));
  }

  actualiser() {
    this.dataGrid?.instance.refresh();
  }

  clearFilters() {
    this.dataGrid?.instance.clearFilter();
    this.initFilters();
    this.dataGrid?.instance.refresh();
  }

  private initFilters() {
    const filters = [];
    if (this.objectType) {
      filters.push(['objectType', '=', this.objectType]);
    }
    if (this.objectId) {
      filters.push(['objectId', '=', this.objectId]);
    }

    if (filters.length) {
      this.filterValue = filters;
    }
  }

  onExporting(e: ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Audit');

    exportDataGrid({
      component: e.component,
      worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'data') {

          // Données
          if (gridCell.column.caption === 'Données' && gridCell.value != null) {
            excelCell.value = `${(gridCell.data.data?.fields?.map((x: TrackingField) => `${x.name}        ${x.oldValue ?? '∅'} ➞ ${x.newValue}\n`))}`;
          }
        }
      },
    }).then(() => {
      const date = formatDate((new Date), "yyyyMMdd-HHmm", "fr-FR");
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${date}-audit.xlsx`);
      });
    });
  }

  setDatagridHeight() {
    if (this.dataGrid == null || this.offsetTopInPx == null) return;
    this.dataGrid.instance.element().style.height = `calc(100vh - ${this.offsetTopInPx}px)`;
  }
}
