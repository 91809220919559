import { GetSocietePourUnUtilisateurDto } from "@lexi-clients/lexi";

export class Societe {
  id: number;
  intitule: string;
  enabled: boolean;
  gestionStock: boolean;
  fretLocalActif: boolean;
  classificationComptableTypeId: number;
  classificationComptableTypeIntitule: string;
  boAutoInsert: boolean;

  constructor(init?: Partial<GetSocietePourUnUtilisateurDto>) {
    Object.assign(this, init);
  }
}
