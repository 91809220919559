<ng-container *ngIf="fluxEtapesPossibles">
  <!-- Liste horizontale des boutons des étapes possibles -->
  <div *ngIf="!useDropDownButtonsAsDisplay" style="display: flex; flex-wrap: wrap; align-items: center;" [title]="motifInterfaceInactive">
    <ng-container *ngFor="let etape of fluxEtapesPossibles">
      <!-- Étape en cours -->
      <!-- <div *ngIf="etape.isCurrentEtape" class="isCurrentEtape">
        <i class="dx-icon-chevrondoubleright" style="font-size: 20px;"></i>
        <span>{{ etape.evenementTypeIntitule | uppercase }}</span>
        <i class="dx-icon-chevrondoubleleft" style="font-size: 20px;"></i>
      </div> -->

      <dx-button *ngIf="!etape.isCurrentEtape"
        style="margin-right: 12px;"
        [ngClass]="{'hasAction' : etape.action}"
        [text]="etape.evenementTypeNomAction ?? etape.evenementTypeIntitule"
        [disabled]="disableButtons || !interfaceActive"
        (onClick)="onClickEtape(etape)"
      ></dx-button>
    </ng-container>
  </div>

  <!-- Actions regroupées dans un DropDownButtons -->
  <ng-container *ngIf="useDropDownButtonsAsDisplay">
    <span *ngIf="fluxEtapesPossibles == null || fluxEtapesPossibles?.length < 1">{{ currentEtape?.evenementTypeNomAction ?? currentEtape?.evenementTypeIntitule }}</span>
    <dx-drop-down-button *ngIf="fluxEtapesPossibles?.length > 0"
      [text]="currentEtape == null ? 'Étape' : (currentEtape.evenementTypeIntitule ?? currentEtape.evenementTypeNomAction)"
      [items]="fluxEtapesPossibles"
      [dropDownOptions]="{ width: 'auto' }"
      itemTemplate="etapeTemplate"
      displayExpr="evenementTypeNomAction"
      [disabled]="disableButtons || !interfaceActive"
      (onItemClick)="onClickEtape($event?.itemData)"
    >
      <div *dxTemplate="let etape of 'etapeTemplate'" [ngClass]="{'hasAction' : etape.action}">
        {{ etape.evenementTypeNomAction ?? etape.evenementTypeIntitule }}
      </div>
    </dx-drop-down-button>
  </ng-container>
</ng-container>