import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { MouvementSens, FluxEtapeDto, LieuStockageDto, FluxStatut, EvenementTypeDto, EvenementTypesLexiClient } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { BonListService } from 'lexi-angular/src/app/services/bon.service';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';
import { lastValueFrom } from 'rxjs';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { formatDate } from '@angular/common';
import { ExportingEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-bon-list-lieu-stockage-datagrid',
  templateUrl: './bon-list-lieu-stockage-datagrid.component.html',
  styleUrls: ['./bon-list-lieu-stockage-datagrid.component.scss'],
})
export class BonListLieuStockageDatagridComponent {
  private _dataGrid: DxDataGridComponent;
  get dataGrid() { return this._dataGrid }
  @ViewChild(DxDataGridComponent, { static: false }) set dataGrid(value: DxDataGridComponent) {
    this._dataGrid = value;
    this.setDatagridHeight();
    this.cdr.detectChanges();
  }

  _offsetTopInPx: number;
  get offsetTopInPx(): number { return this._offsetTopInPx; }
  @Input() set offsetTopInPx(value: number) {
    this._offsetTopInPx = value;
    this.setDatagridHeight();
  }
  MouvementSens = MouvementSens;
  FLUX_STATUT = FluxStatut;
  showMarchandisesLegende = false;
  showreferencesLegende = false;

  _lieuStockage: LieuStockageDto;
  get lieuStockage(): LieuStockageDto {
    return this._lieuStockage;
  }
  @Input() set lieuStockage(value: LieuStockageDto) {
    if (value) {
      this._lieuStockage = value;
      this.initData();
    }
  }

  @Input() fluxEtapeDataSource: FluxEtapeDto[];
  @Input() societeIsFondAutorise = false;
  @Input() currentPartenaireId: number;
  _includeInfosCalculees: boolean = false;
  get includeInfosCalculees() { return this._includeInfosCalculees; }
  @Input() set includeInfosCalculees(value: boolean) {
    this._includeInfosCalculees = value;
    if (value != null) {
      this.initData();
    }
  }

  bonStore: CustomStore;
  evenementTypes: EvenementTypeDto[];
  fluxStatutDataSource = [
    { id: FluxStatut.new, intitule: "Brouillon" },
    { id: FluxStatut.paused, intitule: "En attente" },
    { id: FluxStatut.opened, intitule: "En cours" },
    { id: FluxStatut.closed, intitule: "Finalisé" },
  ];

  constructor(
    private readonly bonListService: BonListService,
    private readonly evenementTypeLexiClient: EvenementTypesLexiClient,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  initData() {
    this.setEvenementTypes();
    const additionalParams = new Map()
      .set("lieuStockageId", this.lieuStockage.id)
      .set("includeInfosCalculees", this.includeInfosCalculees);
    this.bonStore = new CustomStoreService(this.bonListService).getCustomStore(additionalParams);
  }

  async setEvenementTypes() {
    const evenementTypes = await lastValueFrom(this.evenementTypeLexiClient.getAll());
    this.evenementTypes = evenementTypes.sort((a, b) => a.intitule && b.intitule ? a.intitule.localeCompare(b.intitule) : 0);
  }

  refreshDataGrid() {
    this.dataGrid?.instance.refresh();
  }

  clearDatagridFilters() {
    this.dataGrid.instance.clearFilter();
  }

  filterColumnHeader(column: string, values: any) {
    this.dataGrid.instance.columnOption(column, 'filterType', 'include');
    this.dataGrid.instance.columnOption(column, 'filterValues', values);
    this.refreshDataGrid();
  }

  resetFilterColumnHeader(column: string) {
    this.dataGrid.instance.columnOption(column, 'selectedFilterOperation', null);
    this.dataGrid.instance.columnOption(column, 'filterValues', null);
    this.refreshDataGrid();
  }

  filterColumn(column: string, filterOperation: string, value: any) {
    this.dataGrid?.instance.columnOption(column, 'selectedFilterOperation', filterOperation);
    this.dataGrid?.instance.columnOption(column, 'filterValue', value);
    this.refreshDataGrid();
  }

  getEtapeLogo(evenementTypeId: number) {
    return evenementTypeId
      ? 'data:image/png;base64,' + this.fluxEtapeDataSource.find(x => x.evenementTypeId == evenementTypeId)?.evenementTypeLogo
      : "assets/images/Discount.png";
  }

  customizeText(cellInfo: { value: string }) {
    return cellInfo?.value ? cellInfo.value : "—";
  }

  onExporting(e: ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Bons');

    exportDataGrid({
      component: e.component,
      worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'data') {

          // Marchandises
          if (gridCell.column.caption === 'Marchandises' && gridCell.value == null) {
            excelCell.value = `${gridCell.data.nombreMarchandisesEncours ?? 0} / ${gridCell.data.nombreMarchandisesMouvementees ?? 0} / ${gridCell.data.nombreMarchandises ?? 0}`;
          }

          // Références
          if (gridCell.column.caption === 'Réf. mouvementées' && gridCell.value == null) {
            excelCell.value = `${gridCell.data.nombreReferencesMouvementees ?? 0} / ${gridCell.data.nombreReferences ?? 0}`;
          }

          // Source
          if (gridCell.column.caption === 'Source' && gridCell.value == null) {
            excelCell.value = this.currentPartenaireId == gridCell.data.partenaireSourceId
              ? gridCell.data.sourceStockageIntitule
              : gridCell.data.partenaireSourceIntitule;
          }
        }
      },
    }).then(() => {
      const date = formatDate((new Date), "yyyyMMdd-HHmm", "fr-FR");
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${date}-liste-des-bons-d-un-stockage.xlsx`);
      });
    });
  }

  setDatagridHeight() {
    if (this.dataGrid == null || this.offsetTopInPx == null) return;
    this.dataGrid.instance.element().style.height = `calc(100vh - ${this.offsetTopInPx}px)`;
  }
}
