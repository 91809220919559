import { Component, OnInit, ViewChild } from '@angular/core';
import { ArticleCsvImportResultDto, RechercheDxArticleDto, ArticlesLexiClient, ArticleType, ClassificationTypeDto, ClassificationValueDto, ObjectType, Permissions, ClassificationsLexiClient, CreateClassificationDto, ClassificationValuesLexiClient, ClassificationTypesLexiClient } from '@lexi-clients/lexi';
import { AuthService } from '../../settings/auth.service';
import { ArticleListDatagridComponent } from '../../modules/shared/article-list-datagrid/article-list-datagrid.component';
import { DxDataSourceService } from '../../shared/services/dx-data-source.service';
import { ClassificationTypeService } from '../../services/classification-type.service';
import DataSource from 'devextreme/data/data_source';
import { DxTreeListComponent, DxDataGridComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import { NotificationType } from '../../modules/shared/references/references';
import notify from 'devextreme/ui/notify';
import { Subscription, filter, lastValueFrom } from 'rxjs';
import dxTreeList, { SelectionChangedEvent } from 'devextreme/ui/tree_list';
import { DownloadService } from '../../services/download.service';
import { ExportDataGridService } from 'lexi-angular/src/app/shared/services/export-data-grid.service';
import { ColumnButtonClickEvent, ExportingEvent } from 'devextreme/ui/data_grid';
import { CustomStoreService } from '../../services/custom-store.service';
import CustomStore from 'devextreme/data/custom_store';
import { ArticleListService } from '../../services/article.service';
import { PrixListService } from '../../services/prix.service';
import { UniteListService } from '../../services/unite.service';
import { TaxeGroupeListService } from '../../services/taxe-groupe.service';

const showClassificationsStorageKey = "showClassificationsArticleList";
const isSelectionRecursiveArticleList = "isSelectionRecursiveArticleList";
@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss'],
})
export class ArticleListComponent implements OnInit {

  private _treeList: DxTreeListComponent;
  get treeList() { return this._treeList; }
  @ViewChild('treeList') set treeList(value: DxTreeListComponent) {
    this._treeList = value;
    if (value) {
      value.instance.updateDimensions();
      value.instance.element().style.height = "calc(100vh - 190px)";
    }
  }
  readonly articleTypeDataSource = [
    { id: ArticleType.backOffice, intitule: "Stockable" },
    { id: ArticleType.commentaire, intitule: "Commentaire" },
    { id: ArticleType.metadata, intitule: "Metadata" },
    { id: ArticleType.kit, intitule: "Kit" },
    { id: ArticleType.prestation, intitule: "Service" },
    { id: ArticleType.interne, intitule: "Interne" },
    { id: ArticleType.libre, intitule: "Libre" },
    { id: ArticleType.generique, intitule: "Générique" },
    { id: ArticleType.fret, intitule: "Frêt" },
  ];

  @ViewChild("dataGrid") dataGrid: DxDataGridComponent;
  grilleStorageKey = "grille_main_article_list";
  currentSiteIntitule: string;
  private subscriptions = new Subscription();
  currentSiteId: number;
  showImportCsvPopup = false;
  fichierCsv: File[] = [];
  filterValue = [];
  importCsvResultMsg: string;
  importCsvResultMsg2: string;
  selectedArticlesIds: number[];
  selectedArticles: RechercheDxArticleDto[];
  importHasFailed = false;
  showLoader = false;
  initDataGrid = false;
  isModificationAutorisee: boolean = false;
  articleDataSource: CustomStore | RechercheDxArticleDto[];
  classificationTypeDataSource: DataSource;
  classificationValues: ClassificationValueDto[];
  selectedClassificationValues: ClassificationValueDto[];
  showClassifications = false;
  classificationValueIds: number[];
  sansClassificationValue = false;
  isSelectionRecursive = true;
  recuperationTemplateEncours = false;
  selectedClassificationTypeId: number;
  readonly selectedClassificationTypeIdStorageKey = "SelectionArticlesClassificationTypeId";
  selectedPrixId: number;
  readonly selectedPrixIdStorageKey = "SelectionArticlesPrixId";
  rechercheGlobale: string;
  inclureVariante = false;
  showPopupStockSite: boolean = false;
  selectedArticle: RechercheDxArticleDto;
  showStockSite: boolean = false;
  isAffichagePMPAutorise: boolean = false;
  prixCustomStore: CustomStore;
  classificationTypes: ClassificationTypeDto[];
  uniteCustomStore: CustomStore;
  taxeGroupeCustomStore: CustomStore;

  constructor(
    private readonly articlesLexiClient: ArticlesLexiClient,
    private readonly authService: AuthService,
    private readonly articleListService: ArticleListService,
    private readonly dxDataSourceService: DxDataSourceService,
    private readonly classificationTypeListService: ClassificationTypeService,
    private readonly classificationsLexiClient: ClassificationsLexiClient,
    private readonly classificationValuesLexiClient: ClassificationValuesLexiClient,
    private readonly downloadService: DownloadService,
    private readonly uniteListService: UniteListService,
    private readonly prixListService: PrixListService,
    private readonly taxeGroupeListService: TaxeGroupeListService,
    private readonly classificationTypesLexiClient: ClassificationTypesLexiClient,
    private readonly exportDataGridService: ExportDataGridService,
  ) { }

  async ngOnInit() {
    this.isModificationAutorisee = this.authService.securityUserisGrantedWith(Permissions.canModifierFicheArticle);
    this.loadComponentData();
    const params = new Map().set("objectType", ObjectType.article);
    this.classificationTypeDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.classificationTypeListService, params);
    await this.setClassificationValues();
    if (localStorage.getItem(showClassificationsStorageKey) === 'true') {
      this.showClassifications = true;
    }

    if (localStorage.getItem(isSelectionRecursiveArticleList)) {
      this.isSelectionRecursive = true;
    }

    this.subscriptions.add(
      this.authService.currentSiteId$.pipe(
        filter((siteId: number) => siteId != null)
      ).subscribe((siteId: number) => {
          this.currentSiteId = siteId;
          this.currentSiteIntitule = this.authService.currentSite.intitule;
      })
    );
    this.isAffichagePMPAutorise = this.authService.securityUserisGrantedWith(Permissions.canAfficherPmp);
  }

  async loadComponentData() {
    this.selectedArticlesIds = [];
    // LocalStorage
    const storedClassificationType = this.getFromLocalStorage(this.selectedClassificationTypeIdStorageKey, true);
    if (storedClassificationType) {
      this.selectedClassificationTypeId = storedClassificationType;
    }
    // Par défaut, on prend la classification comptable de la société
    else if (this.authService.currentSociete?.classificationComptableTypeId != null) {
      this.selectedClassificationTypeId = this.authService.currentSociete.classificationComptableTypeId;
    }

    const storedTypePrix = this.getFromLocalStorage(this.selectedPrixIdStorageKey, true);
    if (storedTypePrix) this.selectedPrixId = storedTypePrix;

    this.uniteCustomStore = new CustomStoreService(this.uniteListService).getCustomStore();
    this.taxeGroupeCustomStore = new CustomStoreService(this.taxeGroupeListService).getCustomStore();
    this.prixCustomStore = new CustomStoreService(this.prixListService).getSelectBoxCustomStore();
    this.classificationTypes = await lastValueFrom(this.classificationTypesLexiClient.getByObjectType(ObjectType.article));
    this.setDataSource();
  }

  onCellClick(event: any) {
    if(event.column?.dataField == "stockSociete") {
      this.showStockParSite(event.data);
    }
  }
  private getFromLocalStorage(key: string, mustBeNumber?: boolean): any {
    const storedString = localStorage.getItem(key);

    // Si doit être un nombre, si ça ne l'est pas on retire du storage
    if (mustBeNumber && isNaN(Number(storedString))) {
      localStorage.removeItem(key);
      return null;
    }
    return storedString ? JSON.parse(storedString) : null;
  }
  setDataSource(sansClassification: boolean = false) {
    this.sansClassificationValue = sansClassification;

    const additionalParams = new Map().set("isRechercheAvancee", true);
    additionalParams.set("avecVariante", this.inclureVariante);
    if (this.selectedClassificationTypeId != null) additionalParams.set("classificationTypeId", this.selectedClassificationTypeId);
    if (this.selectedPrixId != null) additionalParams.set("prixId", this.selectedPrixId);
    if (this.rechercheGlobale != null) additionalParams.set("rechercheGlobale", this.rechercheGlobale);
    if (!sansClassification && this.classificationValueIds?.length > 0) additionalParams.set("classificationValueIds", this.classificationValueIds);
    if (sansClassification) additionalParams.set("sansClassification", true)
    this.articleDataSource = new CustomStoreService(this.articleListService).getCustomStore(additionalParams, [], { key: 'id' });
  }


  hideStockParSitePopup = async () => {
    this.selectedArticle = null;
  };

  onExporting(e: ExportingEvent, filename: string) {
    this.exportDataGridService.onExporting(e, filename);
  }

  async importDataFromCsv() {
    this.resetImportResultMsg();
    this.showLoader = true;
    try {
      const response: ArticleCsvImportResultDto = await lastValueFrom(this.articlesLexiClient.importArticlesFromCsv(true, this.fichierCsv[0]));

      // Erreur
      if (response.hasFailed) {
        this.importHasFailed = true;
        if (response.errorMessage != null) {
          this.importCsvResultMsg = response.errorMessage;
        }

        else {
          if (response.hasWrongHeadersCount) {
            this.importCsvResultMsg = "Le nombre de colonnes est incorrecte, il y a plus de colonnes que celles attendues.";
          }

          else {
            this.importCsvResultMsg = `Les colonnes suivantes sont invalides: ${response.invalidHeaders.join(', ')}.`;
          }

          if (response.expectedHeaders != null) {
            this.importCsvResultMsg2 = `Colonnes attendues: (${response.expectedHeaders.join(', ')})`;
          }
        }
      }

      else {
        // Importation incomplète
        if (response.articlesOnError.length > 0) {
          let errorMessage = "";
          response.articlesOnError.forEach(x => errorMessage += `Code Article : ${x.codeBo}; Erreur : ${x.messageErreur}\n`);
          this.importCsvResultMsg = `Certains articles n'ont pas pu être importés. Le détail a été copié dans le presse papier`;
          navigator.clipboard.writeText(errorMessage).then(() => console.info(errorMessage)).catch(e => {
            console.error(e);
            console.error(errorMessage);
            this.importCsvResultMsg = `Certains articles n'ont pas pu être importés :\n` + errorMessage;
          });
          this.setDataSource();
        }

        // Succès
        else {
          this.importHasFailed = false;
          this.setDataSource();
          this.importCsvResultMsg = `${response.nbArticlesCreated} article(s) ont été créé(s),  ${response.nbArticlesIgnored} article(s) ont été ignorés. \nL'importation s'est terminée.`;
        }

        // Articles désarchivés
        this.importCsvResultMsg2 = response.codesArticlesDesarchives.length > 0
          ?
          `Certains articles existaient déjà en base de données à l'état supprimé/archivé. Ils ont été désarchivés sans modification supplémentaire.

  Codes des articles désarchivés :

[ ${response.codesArticlesDesarchives.join(", ")} ]`
          : "";
      }

      this.fichierCsv = [];
    }
    finally {
      this.showLoader = false;
    }
  }

  cancelImportCsv() {
    this.showImportCsvPopup = false;
    this.resetImportResultMsg();
    this.fichierCsv = [];
  }

  resetImportResultMsg() {
    this.importCsvResultMsg = null;
    this.importCsvResultMsg2 = null;
  }

  classificationTypeDisplayExpr(type: ClassificationTypeDto) {
    return type && type.codeBo + " - " + type.intitule;
  }

  async expandRecursively(dxTreeList: dxTreeList<any, any>, rowKey: number, rowIndex: number) {
    try {
      await dxTreeList.expandRow(rowKey);
      const children = dxTreeList.getVisibleRows()?.find(x => x.rowIndex == rowIndex)?.node?.children;
      if (children) {
        children.forEach(async (element) => {
          const index = dxTreeList.getRowIndexByKey(element.key);
          await this.expandRecursively(dxTreeList, element.key, index);
        });
      }
    }
    catch { }
  }

  async onClassificationValueSelectionChanged(e: SelectionChangedEvent) {
    // On ne filtre pas si la désélection vient du fait d'avoir mis le filtre 'Sans classification uniquement'
    if (this.sansClassificationValue && e.currentSelectedRowKeys?.length < 1) return;
    const rows = e.component.getVisibleRows();
    for (const element of rows.filter(x => x.isSelected && !x.isExpanded)) {
      await this.expandRecursively(e.component, element.key, element.rowIndex);
    }

    await e.component.refresh();
    this.classificationValueIds = e.component.getSelectedRowKeys('all');
    setTimeout(() => {
      this.setDataSource(false);
    }, 100);
  }

  onToggleClassificationDisplay() {
    this.treeList?.instance?.clearSelection();
    this.dataGrid?.instance.clearSelection();
    this.classificationValueIds = null;
    this.showClassifications = !this.showClassifications;
    if (this.showClassifications) {
      localStorage.setItem(showClassificationsStorageKey, "true");
    }
    else {
      setTimeout(() => {
        this.setDataSource();
      }, 100);

      localStorage.removeItem(showClassificationsStorageKey);
    }
  }

  showStockParSite = async (article: RechercheDxArticleDto) => {
    this.selectedArticle = article;
    this.showPopupStockSite = true;
  }

  async onToggleIsSelectionRecursive() {
    if (this.isSelectionRecursive) {
      localStorage.setItem(isSelectionRecursiveArticleList, "true");
    }
    else {
      localStorage.removeItem(isSelectionRecursiveArticleList);
    }

    await this.treeList?.instance.refresh();
    this.classificationValueIds = this.treeList?.instance.getSelectedRowKeys('all');
    setTimeout(() => {
      this.setDataSource(false);
    }, 100);
  }

  onAddArticleIntoClassification = async (e: { itemData: RechercheDxArticleDto, component: any, toIndex: number }) => {
    if (this.dataGrid?.instance == null) return;
    const rows = e.component.getVisibleRows();
    const classification: ClassificationValueDto = rows?.find(x => x.rowIndex == e.toIndex)?.data;
    let articles: RechercheDxArticleDto[] = this.dataGrid.instance.getSelectedRowsData();
    if ((articles == null || articles.length == 0) && e.itemData != null) {
      articles = [e.itemData];
    }
    const s = articles?.length > 1 ? "s" : "";
    if (articles?.length > 0 && classification != null) {
      const confirmed = await confirm(`Associer ${articles.length} article${s} à la classification '${classification.intitule}'`, `Confirmation`);
      if (confirmed) {
        const dtos: CreateClassificationDto[] = articles.map(x => {
          return {
            itemId: x.id,
            classificationValueId: classification.id,
            type: ObjectType.article,
          };
        });
        await lastValueFrom(this.classificationsLexiClient.create(dtos));
        notify({ closeOnClick: true, message: `Association${s} faite${s} avec succès.` }, NotificationType.Success);
        this.dataGrid.instance.clearSelection();
        setTimeout(() => {
          this.setDataSource(this.sansClassificationValue);
        }, 100);
      }
    }
  }

  async onRemoveClassificationArticle() {
    if (this.dataGrid?.instance == null) {
      notify({ closeOnClick: true, message: `Impossible de récupérer les articles sélectionnés. Essayez de rafraîchir la page.` }, NotificationType.Warning);
      return;
    }

    const articles: RechercheDxArticleDto[] = this.dataGrid.instance.getSelectedRowsData();
    if (articles?.length < 1) {
      notify({ closeOnClick: true, message: `Veuillez sélectionner au moins un article.` }, NotificationType.Warning);
      return;
    }

    const s = articles?.length > 1 ? "s" : "";
    const classifications: ClassificationValueDto[] = this.treeList?.instance?.getSelectedRowsData();
    if (articles?.length > 0 && classifications?.length > 0) {
      const classificationsIntitule = classifications.map(x => x.intitule);
      const m = classifications.length > 1 ? `aux classifications ${classificationsIntitule.join(', ')}` : `à la classifications ${classificationsIntitule[0]}`;
      const confirmed = await confirm(`Supprimer l'association de ${articles.length} article${s} ${m}`, `Confirmation`);
      if (confirmed) {
        await lastValueFrom(this.classificationsLexiClient.deleteMultiple(ObjectType.article, classifications.map(x => x.id), articles.map(x => x.id)));
        notify({ closeOnClick: true, message: `Association${s} supprimée${s} avec succès.` }, NotificationType.Success);
        this.dataGrid.instance.clearSelection();
        setTimeout(() => {
          this.setDataSource();
        }, 100);
      }
    }
  }

  onSansClassificationChanged(e: { value: boolean }) {
    this.setDataSource(e.value);
  }

  getTexteLignesSelectionees() {
    const selectedRows = this.dataGrid?.instance.getSelectedRowsData();
    const s = selectedRows?.length > 1 ? 's' : '';
    return `${selectedRows?.length ?? 0} ligne${s} sélectionnée${s}`
  }

  clearDataGridFilters() {
    this.dataGrid?.instance?.clearFilter();
    this.dataGrid?.instance?.clearSelection();
    this.dataGrid?.instance?.clearSorting();
  }

  clearTreeListFilters() {
    this.treeList?.instance?.clearFilter();
    this.treeList?.instance?.clearSelection();
    this.treeList?.instance?.clearSorting();
    this.classificationValueIds = null;
  }

  async setClassificationValues() {
    this.classificationValues = this.selectedClassificationTypeId == null ? []
      : await lastValueFrom(this.classificationValuesLexiClient.getByClassificationTypeId(this.selectedClassificationTypeId));
  }

  async downloadTemplate() {
    try {
      this.recuperationTemplateEncours = true;
      const response = await lastValueFrom(this.articlesLexiClient.getTemplateImportCsv(this.inclureVariante, 'response'));
      this.downloadService.directDownloadFile(response, 'csv');
    }
    finally {
      this.recuperationTemplateEncours = false;
    }
  }

  onClassificationTypeChanged() {
    // LocalStorage
    this.selectedClassificationTypeId != null
      ? localStorage.setItem(this.selectedClassificationTypeIdStorageKey, JSON.stringify(this.selectedClassificationTypeId))
      : localStorage.removeItem(this.selectedClassificationTypeIdStorageKey);
    this.setDataSource(this.sansClassificationValue);
  }

  onPrixChanged() {
    // LocalStorage
    this.selectedPrixId != null
      ? localStorage.setItem(this.selectedPrixIdStorageKey, JSON.stringify(this.selectedPrixId))
      : localStorage.removeItem(this.selectedPrixIdStorageKey);
    this.setDataSource(this.sansClassificationValue);
  }

  onSelectionChanged(e: { selectedRowKeys: number[], selectedRowsData: RechercheDxArticleDto[] }) {
    this.selectedArticlesIds = e.selectedRowKeys;
    this.selectedArticles = e.selectedRowsData;
  }

}
