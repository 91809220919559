import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PersonnalisationGrilleDto } from '@lexi-clients/lexi';
import { DxoStateStoringComponent } from 'devextreme-angular/ui/nested';
import { StorageService } from 'lexi-angular/src/app/services/storage.service';
import { filter } from 'rxjs';

@Directive({
  selector: '[appStorage]'
})
export class StorageDirective implements OnChanges {
  private grille: PersonnalisationGrilleDto;
  @Input() withFilters: boolean = true;

  constructor(
    private readonly dxoStateStoring: DxoStateStoringComponent,
    private readonly storageService: StorageService
  ) {
    // On s'assure que le type soit "custom" pour renseigner ses méthodes Load et Save
    dxoStateStoring.type = "custom";

    // On s'abonne à la sélection de grille
    this.storageService.currentGrilleSelected$.pipe(
      filter(x => x == null || x.storageKey == this.dxoStateStoring.storageKey)
    ).subscribe((grille) => {
      if (grille == null) {
        this.initService();
        return;
      }
      this.grille = grille;
    });

    // On initialise les méthodes du composant
    this.initService();
  }

  async initService() {
    this.dxoStateStoring.customLoad = this.load;
    this.dxoStateStoring.customSave = this.save;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['withFilters'] && !changes['withFilters'].isFirstChange()) {
      await this.initService();
    }
  }

  load = async () => {
    return await this.storageService.loadDxDataGridState(this.grille, this.dxoStateStoring.storageKey, this.withFilters);
  }

  save = async (state: any) => {
    if (this.grille == null) return;
    await this.storageService.saveDxDataGridState(this.grille, state);
  }
}
