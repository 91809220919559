import { Injectable } from '@angular/core';
import { PersonnalisationGrilleDto, PersonnalisationGrillesLexiClient } from '@lexi-clients/lexi';
import { BehaviorSubject, filter, lastValueFrom, Observable } from 'rxjs';
import { AuthService } from '../settings/auth.service';
import { LexiUser } from '@lexi/oidc-uaa';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  readonly storageKeyPrefix = "LX_GRILLE_";
  private currentUserId: number;
  private grilleSaved$: BehaviorSubject<PersonnalisationGrilleDto> = new BehaviorSubject(null);
  private grilleSelected$: BehaviorSubject<PersonnalisationGrilleDto> = new BehaviorSubject(null);

  get currentGrilleSaved$(): Observable<PersonnalisationGrilleDto> {
    return this.grilleSaved$;
  }

  get currentGrilleSelected$(): Observable<PersonnalisationGrilleDto> {
    return this.grilleSelected$;
  }

  constructor(
    private readonly personnalisationGrillesLexiClient: PersonnalisationGrillesLexiClient,
    private readonly authService: AuthService
  ) {
    this.authService.currentUser$
        .pipe(filter((user: LexiUser) => user != null))
        .subscribe((user: LexiUser) => (this.currentUserId = user.id));
  }

  // #region Méthodes principales publiques
  public async getGrille(gridStorageKey: string): Promise<PersonnalisationGrilleDto> {
    // LocalStorage
    const storedGrilles = this.getFromLocalStorage(gridStorageKey);
    if (storedGrilles?.length > 0) {
      const selectedGrille = storedGrilles.find(x => (x as any).isSelected);
      return selectedGrille != null ? selectedGrille : storedGrilles[0];
    }

    // Serveur
    const serveurGrilles = await this.getFromServeur(gridStorageKey);
    if (serveurGrilles?.length > 0) {
      return serveurGrilles[0];
    }

    // Création
    const grille = await this.initOnServeur(gridStorageKey);
    return grille;
  }

  public getFromLocalStorage(gridStorageKey: string): PersonnalisationGrilleDto[] {
    if (gridStorageKey == null) return null;
    const infosString: string = localStorage.getItem(this.getLocalStorageKey(gridStorageKey));
    if (infosString == null) return null;
    const infos: PersonnalisationGrilleDto[] = JSON.parse(infosString);
    return infos;
  }

  public async getFromServeur(gridStorageKey: string): Promise<PersonnalisationGrilleDto[]> {
    const res = await lastValueFrom(this.personnalisationGrillesLexiClient.getByStorageKey(gridStorageKey));
    if (res.length > 0) {
      this.saveToLocalStorage(gridStorageKey, res);
    }
    return res;
  }

  public saveToLocalStorage(gridStorageKey: string, grilles: PersonnalisationGrilleDto[]): void {
    if (gridStorageKey == null) return null;
    localStorage.setItem(this.getLocalStorageKey(gridStorageKey), JSON.stringify(grilles));
  }

  /** Sauvegarde la grille dans le localStorage et sur le serveur */
  public async saveDxDataGridState(grille: PersonnalisationGrilleDto, state: object): Promise<PersonnalisationGrilleDto> {
    // grille ne doit pas être null
    if (grille == null) {
      console.error("Impossible d'enregistrer la personnalisation d'une grille vide.");
      return;
    }

    // Sauvegarde serveur
    grille.data = state != null ? JSON.stringify(this.ensureValidState(state)) : null;
    grille = await this.saveDxDataGridStateOnServer(grille);

    // Sauvegarde localStorage
    (grille as any).isSelected = true;
    let infos: PersonnalisationGrilleDto[] = this.getFromLocalStorage(grille.storageKey) ?? [];
    infos = infos.some(el => el.id === grille.id)
      ? infos.map(el => {
        if (el.id === grille.id) return grille;
        (el as any).isSelected = false;
        return el;
      })
      : [...infos, grille];
    localStorage.setItem(this.getLocalStorageKey(grille.storageKey), JSON.stringify(infos));

    // Émission de la nouvelle grille
    this.grilleSaved$.next(grille);

    return grille;
  }

  /** Charge la grille depuis le localStorage si elle existe, sinon la récupère du serveur */
  public async loadDxDataGridState(grille: PersonnalisationGrilleDto, gridStorageKey: string, withFilters: boolean): Promise<any> {
    if (grille == null) {
      grille = await this.getGrille(gridStorageKey);
      this.grilleSelected$.next(grille);
    }

    if (grille?.data == null) return;

    // Récupération du state
    const state = JSON.parse(grille.data);
    state.selectedRowKeys = null;

    // Suppression des filtres si option
    if (!withFilters) {
      if (state.columns) {
        state.columns.forEach((column: any) => {
          column.filterValue = null;
        });
      }
      state.filterValue = null;
    }

    return state;
  }

  public selectGrille(grille: PersonnalisationGrilleDto) {
    this.grilleSelected$.next(grille);
  }

  public getLocalStorageKey(gridStorageKey: string): string {
    return this.storageKeyPrefix + gridStorageKey + "_" + this.currentUserId;
  }
  // #endregion

  // #region Méthodes privées
  private async saveDxDataGridStateOnServer(grille: PersonnalisationGrilleDto): Promise<PersonnalisationGrilleDto> {
    try {
      return await lastValueFrom(this.personnalisationGrillesLexiClient.createOrUpdate(grille));
    } catch { return null; }
  }

  private ensureValidState(state: object): object {
    if (state["0"] == "{") {
      // Documentation : https://js.devexpress.com/Angular/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/stateStoring/
      return {
        filterValue: state["filterValue"],
        focusedRowKey: state["focusedRowKey"],
        selectedRowKeys: state["selectedRowKeys"],
        selectionFilter: state["selectionFilter"],
        filterPanel: state["filterPanel"],
        paging: state["paging"],
        searchPanel: state["searchPanel"],
        columns: state["columns"],
        allowedPageSizes: state["allowedPageSizes"],
        pageIndex: state["pageIndex"],
        pageSize: state["pageSize"],
      };
    }
    return state;
  }

  /** Première création de la grille pour cette storageKey */
  private async initOnServeur(gridStorageKey: string): Promise<PersonnalisationGrilleDto> {
    const res = await this.saveDxDataGridStateOnServer({ storageKey: gridStorageKey, intitule: "Par défaut" });
    return res;
  }
  //#endregion
}