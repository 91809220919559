import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ContactDetailDto, ContactFilterDto, ContactResultDto, ContactsLexiClient, ContactToCreateDto, TypeUtilisateurRuunui, TypeUtilisateur, RoleDto, RolesLexiClient, RoleTypes, RoleUtilisateurLexiClient, ObjectType, RoleUtilisateurListDto, RoleUtilisationDto, UserDto, UtilisateursLexiClient, UtilisateurRelationLexiClient } from '@lexi-clients/lexi';
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { NotificationType } from '../references/references';
import { AuthService } from '../../../settings/auth.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: "app-contact-list",
  templateUrl: "./contact-list.component.html",
  styleUrls: ["./contact-list.component.scss"],
})
export class ContactListComponent implements OnChanges, OnInit {
  private _dataGrid: DxDataGridComponent;
  get dataGrid() { return this._dataGrid; }
  @ViewChild(DxDataGridComponent) set dataGrid(value: DxDataGridComponent) {
    this._dataGrid = value;
    if (value && this.heightToDeduce != null) {
      value.instance.element().style.height = `calc(100vh - ${this.heightToDeduce}px)`;
    }
  }

  private _heightToDeduce: number;
  get heightToDeduce() { return this._heightToDeduce; }
  @Input() set heightToDeduce(value: number) {
    this._heightToDeduce = value;
    if (value && this.dataGrid != null) {
      this.dataGrid.instance.element().style.height = `calc(100vh - ${value}px)`;
    }
  }

  @ViewChild("formPartenaire") formPartenaire: DxFormComponent;
  @ViewChild("formCollaborateur") formCollaborateur: DxFormComponent;
  @Input() clientId: number;
  @Input() currentUserType: TypeUtilisateurRuunui;

  rowIsSelected = false;
  contacts: ContactResultDto[] = [];
  isCollaborateur = false;

  showCreationContactPopup: boolean = false;
  contactToCreate: ContactToCreateDto = {};

  showModificationContactPopup: boolean = false;
  contactToUpdate: ContactResultDto;
  contactToUpdateRoles: RoleUtilisateurListDto[] = [];

  roles: RoleDto[] = [];
  selectedRoleIds: number[] = []

  utilisateurs: UserDto[] = [];
  associationsPossibles: UserDto[] = [];

  get roleContact(): RoleDto {
    return this.roles?.find(r => r.roleType == RoleTypes.contact);
  }

  constructor(
    private readonly contactService: ContactsLexiClient,
    private readonly authService: AuthService,
    private readonly roleService: RolesLexiClient,
    private readonly userService: UtilisateursLexiClient,
    private readonly userRelationService: UtilisateurRelationLexiClient,
    private readonly roleUtilisateurService: RoleUtilisateurLexiClient
  ) {}

  async ngOnInit() {
    await this.setUtilisateurs();
    await this.setRoles();
    await this.setContacts();
  }

  async setUtilisateurs() {
    this.utilisateurs = await lastValueFrom(this.userService.getUtilisateurs(TypeUtilisateur.partenaire, true, false));
  }

  fieldChanged() {
    this.utilisateurs.forEach(u => {
      // Si l'email qu'on a rentré correspond à celui d'un des utilisateurs existants,
      // on ajoute cet utilisateur aux associations possibles
      if  (
        u.email
        && this.contactToCreate.email
        && u.email.toLowerCase().includes(this.contactToCreate.email.toLowerCase())
      ) {
        if (!this.associationsPossibles.includes(u)) {
          this.associationsPossibles.push(u);
        }
      }

      // Sinon si il fait parti des associations possibles mais qu'il ne correspond pas à
      // l'email rentré, on le retire de la liste
      else if (this.associationsPossibles.includes(u)){
        this.associationsPossibles.splice(this.associationsPossibles.indexOf(u), 1);
      }
    });
  }

  async createFromUser(e: { data: UserDto }): Promise<void> {
    const roleId: number = this.roleContact?.id;
    if (!roleId) {
      this.notifyWithCloseOnClick("Erreur lors de la récupération du rôle 'Contact'. Recharger la page et réessayer.", NotificationType.Error);
      return;
    }

    if (!e.data) {
      this.notifyWithCloseOnClick("Erreur : Utilisateur introuvable.", NotificationType.Error);
      return;
    }

    const dto: RoleUtilisationDto = {
      utilisateurId: e.data.id,
      roleId,
      objectId: this.clientId.toString(),
      objectType: ObjectType.partenaire
    };

    if (this.isCollaborateur){
      await lastValueFrom(this.userRelationService.creer(dto));
      this.notifyWithCloseOnClick("Association créée avec succès.", NotificationType.Success);
    }

    else {
      await lastValueFrom(this.userRelationService.creer(dto, false));
      this.notifyWithCloseOnClick("Demande d'association envoyée.", NotificationType.Success);
    }

    await this.setContacts();
    this.showCreationContactPopup = false;
    this.contactToCreate = {};
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes?.clientId) {
      this.clientId = changes.clientId?.currentValue;
      this.isCollaborateur = this.authService.securityUser?.typeUtilisateur == TypeUtilisateur.collaborateur ? true : false;
      await this.setContacts();
    }
  }

  async setContacts(
    
    filtre: ContactFilterDto = {
      inclureInactif: true,

      objetLies: [{objectId: this.clientId ? this.clientId.toString() : this.authService.securityUser.partenaireId?.toString(),
        objectType: ObjectType.partenaire}]
    }
  ) {
    this.contacts = await lastValueFrom(this.contactService.rechercher(filtre));
  }

  async setRoles() {
    this.roles = await lastValueFrom(this.roleService.getAll([RoleTypes.contact]));
  }

  async setContactRoles() {
    this.selectedRoleIds = [];
    this.contactToUpdateRoles = await lastValueFrom(this.roleUtilisateurService.getRoleUtilisateur(ObjectType.partenaire, this.clientId.toString(), this.contactToUpdate.id));

    for(const role of this.contactToUpdateRoles) {
      if(role.isDeleted) {
        const index = this.contactToUpdateRoles.indexOf(role);
        if(index != -1) {
          this.contactToUpdateRoles.splice(index, 1);
        }
        continue;
      }
      this.selectedRoleIds.push(role.roleId);
    }
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    const addRowButton = toolbarItems.find(x => x.name == "addRowButton");
    if(addRowButton)  {
      addRowButton.options.onClick = () => {
        this.contactToCreate = {};
        this.showCreationContactPopup = true;
      }
    }

    toolbarItems.unshift({
      location: "before",
      widget: "dxButton",
      options: {
        text: "Réinitialiser accès portail",
        onClick: this.reinitialiserAccesPortail.bind(this),
        disabled: !this.rowIsSelected,
        hint: "Réinitiliser l'accès au portail",
      },
    });
  }

  onSelectionChanged() {
    const length = this.dataGrid.instance.getSelectedRowsData().length;
    this.rowIsSelected = length > 0;
    this.dataGrid.instance.repaint();
  }

  async reinitialiserAccesPortail() {
    const contact: ContactResultDto = this.dataGrid.instance.getSelectedRowsData()[0];
    await lastValueFrom(this.contactService.reinitialiserAccesPortail(contact.id));
    notify(
      `Accès réinitialisé pour l'utilisateur ${contact.intitule}`,
      NotificationType.Success
    );
  }

  async onSubmit(e: { changes: any[]; component: any; element: HTMLElement }) {
    const contact: ContactResultDto = e.changes?.[0]?.key;
    const action = e.changes?.[0]?.type;

    if (e.changes?.length !== 1 || !contact) {
      await this.setContacts();
      return;
    }

    if (contact.accesPortail === null) {
      contact.accesPortail = false;
    }

    if (contact.actif === null) {
      contact.actif = false;
    }

    if(action == "remove") {
      await lastValueFrom(this.contactService._delete(contact.id, this.clientId));
    }

    await this.setContacts();
  }

  private formIsValid(): boolean {
    return this.isCollaborateur
      ? this.formCollaborateur.instance.validate()?.isValid
      : this.formPartenaire.instance.validate()?.isValid;
  }

  async createContact() {
    if (!this.formIsValid()) {
      return;
    }

    if (
      this.associationsPossibles.length == 1 &&
      this.associationsPossibles[0].email.toLowerCase() == this.contactToCreate.email.toLowerCase()
    ) {
      this.notifyWithCloseOnClick("Un utilisateur avec cet email existe déjà, veuillez plûtot l'associer à ce partenaire", NotificationType.Warning);
      return;
    }

    this.contactToCreate.clientId = this.clientId;

    if (this.contactToCreate.intitule == null || this.contactToCreate.email == null) {
      this.notifyWithCloseOnClick("Veuillez renseigner au moins l'intitulé et l'email du contact à ajouter", NotificationType.Warning);
      return;
    }

    const newContact: ContactDetailDto = await lastValueFrom(this.contactService.create(this.contactToCreate));
    if (!newContact) {
      this.notifyWithCloseOnClick("Erreur lors de la création du contact", NotificationType.Error);
      return;
    }
    await this.manageRoleUtilisateurs(newContact.id);
    this.notifyWithCloseOnClick("Utilisateur crée avec succès.", NotificationType.Success);

    await this.setContacts();
    await this.setUtilisateurs();
    this.showCreationContactPopup = false;
    this.contactToCreate = {};
  }

  async closeModificationContactPopup() {
    await this.setContacts();
    this.showModificationContactPopup = false;
    this.contactToUpdate = null;
    this.contactToUpdateRoles = [];
  }

  async updateContact() {
    const contactToUpdate: ContactDetailDto = this.contactToUpdate;
    contactToUpdate.accesAutorise = this.contactToUpdate.accesPortail;
    await lastValueFrom(this.contactService.update(contactToUpdate.id, contactToUpdate));
    await this.manageRoleUtilisateurs(this.contactToUpdate.id);
    this.notifyWithCloseOnClick("Utilisateur modifié avec succès.", NotificationType.Success);
    await this.setContacts();
  }

  async manageRoleUtilisateurs(contactId: number) {
    for(const role of this.roles) {
      const roleUtilisateurExistant: boolean = this.contactToUpdateRoles.find(r => r.roleId == role.id) != undefined;

      // Si c'est un nouveau rôle sélectionné, on crée un UtilisateurRelation
      if(this.selectedRoleIds.includes(role.id) && !roleUtilisateurExistant) {
        const dto: RoleUtilisationDto = {
          utilisateurId: contactId,
          roleId: role.id,
          objectId: this.clientId.toString(),
          objectType: ObjectType.partenaire
        };

        await lastValueFrom(this.roleUtilisateurService.create(dto));
      }

      // Si c'est un rôle qu'on a enlevé, on supprime l'UtilisateurRelation correspondant
      else if(!this.selectedRoleIds.includes(role.id) && roleUtilisateurExistant) {
        await lastValueFrom(this.roleUtilisateurService._delete(ObjectType.partenaire, this.clientId.toString(), contactId, role.id));
      }
    }
  }

  async showContactDetails(contact: ContactResultDto) {
    if (this.isCollaborateur){
      this.contactToUpdate = contact;
      await this.setContactRoles();
      this.showModificationContactPopup = true;
    }
  }

  onRoleSelected(selected: boolean, role: RoleDto) {

    if(selected && this.selectedRoleIds.find(id => id == role.id) == undefined) {
      this.selectedRoleIds.push(role.id)
    }

    else if(!selected) {
      const index = this.selectedRoleIds.indexOf(role.id);
      if(index != -1) {
        this.selectedRoleIds.splice(index, 1);
      }
    }

  }

  private notifyWithCloseOnClick(message: string, type: NotificationType) {
    notify({ closeOnClick: true, message }, type);
  }

  async onKeyDown(e) {
    if (e.event.key === "Insert") {
        this.dataGrid.instance.addRow();
    }
  }
}
