import { Injectable, OnDestroy } from '@angular/core';
import { ObjectDto, ObjectPermissionsDto, SecurityLexiClient, UserDto } from '@lexi-clients/lexi';
import { EventTypes, LoginResponse, OidcSecurityService, OpenIdConfiguration, PublicEventsService } from 'angular-auth-oidc-client';
import { BehaviorSubject, fromEvent, lastValueFrom, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { mergeMap, map, filter, switchMap, tap, catchError, take } from 'rxjs/operators';
import { LexiUser } from './lexi-user';

const WELL_KNOWN_SUFFIX = `/.well-known/openid-configuration`;
@Injectable({ providedIn: 'root' })
export class UaaService implements OnDestroy {
  private user$: BehaviorSubject<LexiUser> = new BehaviorSubject(LexiUser.ANONYMOUS);
  private checkAuthResponseSubject$: Subject<LoginResponse> = new Subject();
  private openIdConfigurationSubject: BehaviorSubject<OpenIdConfiguration> = new BehaviorSubject(null);
  private openIdConfiguration$: Observable<OpenIdConfiguration>;

  private objects: ObjectDto[] = [];

  private userDataSubscription: Subscription;
  private offlineSubscription: Subscription;

  get openIdConfiguration(): Observable<OpenIdConfiguration> {
    return this.openIdConfiguration$;
  }

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private lexiSecurityService: SecurityLexiClient,
    private eventService: PublicEventsService
  ) {
    console.log(`Starting UaaService in ${navigator.onLine ? 'online' : 'offline'} mode`);
    this.offlineSubscription = this.offline$().subscribe((isOffline) => (isOffline ? this.onOffline() : this.onBackOnline()));
    this.openIdConfiguration$ = this.openIdConfigurationSubject.asObservable();
  }

  /**
   *
   * @param objects
   */
  async init(objects: ObjectDto[], initialUserIfOffline: LexiUser = LexiUser.ANONYMOUS): Promise<boolean> {
    this.objects = objects;

    this.eventService.registerForEvents()
      .pipe(
        filter((event) => event.type === EventTypes.ConfigLoaded),
        switchMap(() => this.oidcSecurityService.getConfiguration()),
        tap((response) =>
        {
          // console.log('OpenID Configuration Response: ', response);
          this.openIdConfigurationSubject.next(response)
        }
        ),
        catchError((err) => {
          console.warn('OidcSecurityService::authConfig failed', err);
          return of(false);
        })
      )
      .subscribe();

    if (navigator.onLine) {
      await this.onBackOnline(initialUserIfOffline);
    } else {
      this.setUser(initialUserIfOffline);
    }

    return this.user$.value.isAuthenticated();
  }

  ngOnDestroy() {
    this.offlineSubscription?.unsubscribe();
    this.userDataSubscription?.unsubscribe();
  }

  private setUser(user: LexiUser) {
    if (!this.user$) {
      this.user$ = new BehaviorSubject(user);
    } else if (JSON.stringify(this.user$.value) !== JSON.stringify(user)) {
      this.user$.next(user);
    }
  }

  private async onBackOnline(anonymousUser = LexiUser.ANONYMOUS) {

    /* Check auth */
    let isAlreadyAuthenticated;
    await lastValueFrom(this.oidcSecurityService.checkAuth())
      .then((response) => {
        // console.log('OidcSecurityService::checkAuth success: ', response);
        this.checkAuthResponseSubject$.next(response);
        isAlreadyAuthenticated = response.isAuthenticated;
      })
      .catch((err) => {
        console.warn('OidcSecurityService::checkAuth failed: ', err);
        isAlreadyAuthenticated = false;
      });

    this.userDataSubscription?.unsubscribe();

    /* Subbscribe to userData */
    this.userDataSubscription = this.oidcSecurityService.userData$
      .pipe(
        mergeMap((oidcUser) => {
          // console.log('UaaService::onBackOnline subscribe user info: ', oidcUser);
          return this.getLexiUser(oidcUser.userData);
        })
      )
      .subscribe((user: LexiUser) => {
        // console.log('UaaService::onBackOnline subscribe set user: ', user);
        this.setUser(user);
      });
  }

  private onOffline() {
    this.userDataSubscription?.unsubscribe();
  }

  get currentUser(): LexiUser {
    return this.user$?.value;
  }

  get currentUser$(): Observable<LexiUser> {
    return this.user$.asObservable();
  }

  get checkAuthResponse$(): Observable<LoginResponse> {
    return this.checkAuthResponseSubject$;
  }

  get token(): Observable<string> {
    return this.oidcSecurityService.getAccessToken();
  }

  public authenticate(objects: ObjectDto[]): void {
    this.objects = objects;
    this.oidcSecurityService.authorize();
  }

  public logout() {
    this.oidcSecurityService.logoff().pipe(take(1)).subscribe((result) => {
      console.log('UaaService::logoff ', result)

      if (this.user$?.value !== LexiUser.ANONYMOUS) {
        this.setUser(LexiUser.ANONYMOUS);
      }
    });
  }

  /**
   * Déclenche la récupération des droits de l'utilisateur connecté avec la nouvelle Société ou le nouveau Site.
   * L'utilisateur avec ses nouveaux droits sera publié sur currentUser$
   * @param objects
   */
  public setObjects(objects: ObjectDto[]): void {
    this.objects = objects;
    // Vérifie l'auth et récupère les droits depuis le serveur Lexi avec le nouveau societeId et/ou siteId
    this.onBackOnline();
  }

  private getLexiUser(oidcUser: any): Observable<LexiUser> {
    // console.log('getLexiUser', oidcUser);
    if (!oidcUser?.sub) {
      return of(LexiUser.ANONYMOUS);
    }
    return this.lexiSecurityService.registerUser(oidcUser.sub, oidcUser.upn)
    .pipe(

      map((userDto: UserDto): LexiUser => new LexiUser(
        userDto.id, oidcUser.sub, userDto.intitule, oidcUser.email, userDto.equipeParDefautId, userDto.equipeIds, userDto.isRoot,
        userDto.typeUtilisateur, userDto.accesAutorise, userDto.partenaireId, userDto.partenaireIds, {}, userDto.siteId
      )),

      mergeMap(
        (lexiUser: LexiUser): Observable<LexiUser> => {
          if(lexiUser.accesAutorise) {
            return this.lexiSecurityService.getUserActivites({ subject: oidcUser.sub, objectDtos: this.objects })
            .pipe( // modifier http get request + param
              map(
                (activiteDtos: ObjectPermissionsDto[]): LexiUser => {
                  activiteDtos.forEach((dto) => Object.assign(lexiUser.activites, { [`${dto.objectId},${dto.objectType}`]: dto.permissions }));
                  return lexiUser;
                }
              )
            )
          }

          else {
            return of(lexiUser);
          }
        }
      )
    );
  }

  private offline$(): Observable<any> {
    return merge<any>(
      fromEvent(window, 'offline').pipe(
        map((): boolean => {
          console.log('Switching UaaService to offline mode');
          return true;
        })
      ),
      fromEvent(window, 'online').pipe(
        map((): boolean => {
          console.log('Switching UaaService to online mode');
          return false;
        })
      )
    );
  }

}
