/**
 * Lexi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum TypeTraitement {
    importBackOffice = 'importBackOffice',
    exportPiece = 'exportPiece',
    suppressionReglement = 'suppressionReglement',
    ajoutReglement = 'ajoutReglement',
    synchronisationDifferentielle = 'synchronisationDifferentielle',
    synchronisationTarifs = 'synchronisationTarifs',
    synchronisationCodeBarre = 'synchronisationCodeBarre',
    synchronisationClients = 'synchronisationClients',
    exportComptagePreparation = 'exportComptagePreparation',
    exportDossier = 'exportDossier',
    exportComptageReception = 'exportComptageReception',
    exportComptageRegules = 'exportComptageRegules',
    ajoutReglementClient = 'ajoutReglementClient',
    updateConnaissement = 'updateConnaissement',
    paiementEnLigne = 'paiementEnLigne',
    modificationReglementPiece = 'modificationReglementPiece',
    exportConnaissement = 'exportConnaissement',
    exportActivite = 'exportActivite'
};

