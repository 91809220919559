

<dx-popup
title="Importer des données"
[(visible)]="showImportPopup"
(onHiding)="onCloseImportPopup()"
[showCloseButton]="true"
[resizeEnabled]="true"
[width]="450"
[height]="370"
>
  <dx-file-uploader #fileUploader
    [multiple]="false"
    [(value)]="files"
    name="files[]"
    [allowedFileExtensions]="['.xlsx']"
    uploadMode="useForm"
    selectButtonText="Choisir un fichier Excel"
    labelText="ou Glisser/Déposer le fichier"
    readyToUploadMessage=""
    class="uploader">
  </dx-file-uploader>

  <div style="display: flex; justify-content: center; margin-top: 12px;">
    <dx-button
      type="danger"
      text="Annuler"
      style="margin: 0 10px 0 20px;"
      (onClick)="onCloseImportPopup()"
    ></dx-button>
    <dx-button
      type="default"
      text="Importer les données"
      style="margin: 0 10px 0 20px;"
      (onClick)="importDataFile()"
      [disabled]="files.length == 0"
    ></dx-button>
  </div>

  <div *ngIf="retoursImport.length > 0">
    <ul>
      <li *ngFor="let r of retoursImport">{{ r }}</li>
    </ul>
  </div>
</dx-popup>