import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-popup-import-fichier-excel',
  templateUrl: './popup-import-fichier-excel.component.html',
  styleUrls: ['./popup-import-fichier-excel.component.scss']
})
export class PopupImportFichierExcelComponent implements OnInit {
  private _showImportPopup: boolean;
  get showImportPopup(): boolean {
    return this._showImportPopup;
  }
  @Input() set showImportPopup(value: boolean) {
    if (value != null && value != undefined) {
      this._showImportPopup = value;
      this.files = [];
    }
  }

  @Input() retoursImport: string[];

  @Output() closeImportPopup = new EventEmitter<File>();

  files: File[] = [];

  ngOnInit() {
  }

  onCloseImportPopup() {
    this.closeImportPopup.emit();
  }

  importDataFile() {
    this.closeImportPopup.emit(this.files[0]);
  }
}
